import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import iDOOLogo from "../../../Images/logos/iDOO-Logo.svg";
import iNOEXLogo from "../../../Images/logos/iNOEX-Logo.png";
import iBAlogo from "../../../Images/logos/iBA-Logo.png";
import CiTEXLogo from "../../../Images/logos/CiTEX-Logo.svg";
import PIXARGUSLogo from "../../../Images/logos/PIXARGUS-Logo.svg";

function getLogo(job) {
  switch (job.subcompany) {
    case "iDOO GmbH":
      return {
        logo: iDOOLogo,
        alt: "iDOO-Logo",
      };
    case "iNOEX":
      return {
        logo: iNOEXLogo,
        alt: "iNOEX-Logo",
      };
    case "iBA":
      return {
        logo: iBAlogo,
        alt: "iBA-Logo",
      };
    case "CiTEX":
      return {
        logo: CiTEXLogo,
        alt: "CiTEX-Logo",
      };
    case "PIXARGUS":
      return {
        logo: PIXARGUSLogo,
        alt: "PIXARGUS-Logo",
      };
    default:
      return {
        logo: null,
        alt: "",
      };
  }
}

const StandardPersonioList = ({ filteredJobs, translations }) => {
  return Object.keys(filteredJobs).map((category, index) => {
    return (
      <div
        key={`key_${category}`}
        className={`personio-list-jobs-wrapper ${index === 0 ? "mt-medium" : ""}`.trim()}
      >
        <h3 className="personio-list-jobs-category d-none">{category}</h3>

        <ul className="personio-list-jobs">
          {filteredJobs[category].map((job) => {
            const logo = getLogo(job);

            return (
              <li key={`key_${job.id}`} className="personio-list-jobs-item">
                <NavLink to={job.slug.split("?")[0]}>
                  <img src={logo.logo} alt={logo.alt} />

                  <span> {job.name} </span>

                  {translations.jobDetails}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });
};

StandardPersonioList.propTypes = {
  filteredJobs: PropTypes.instanceOf(Object),
  // setSelectedJob: PropTypes.func,
  translations: PropTypes.instanceOf(Object),
};

export default StandardPersonioList;
