import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import StandardPersonioList from "./StandardPersonioListView";

import TranslationContext from "../../../Context/Translation";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function scrollToPersonio() {
  const deElement = document.getElementById("stellenmarkt_1226");
  const enElement = document.getElementById("vacant_positions_1226");

  const element = deElement || enElement;

  if (element) {
    const navigationElement = document.querySelector(".navbar navbar-expand-xl");

    let navigationHeight = 0;

    if (navigationElement) {
      navigationHeight = navigationElement.offsetHeight;
    }

    window.scroll({
      top:
        element.getBoundingClientRect().top +
        window.scrollY -
        element.offsetHeight -
        navigationHeight,
      left: 0,
      behavior: "smooth",
    });
  }
}

/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

const StandardPersonio = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    jobs,
    companies,
    categories,
    // ctaHeadline,
    // ctaTeaser,
    seniority,
    preselectedSeniority,
    animation,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const [filteredJobs, setFilteredJobs] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSeniority, setSelectedSeniority] = useState(preselectedSeniority);

  const query = useQuery();

  const translateSeniority = useCallback(
    (currentSeniority) => {
      let translatedSeniority = "";

      switch (true) {
        case currentSeniority === "student":
          translatedSeniority =
            activeTranslation.twoLetterIsoCode === "de"
              ? "Studierende/Mitarbeitende im Praktikum/Auszubildende"
              : "Student/Trainee/Intern";
          break;

        case currentSeniority === "entry-level":
          translatedSeniority =
            activeTranslation.twoLetterIsoCode === "de" ? "Berufseinstieg" : "Entry-level";
          break;

        case currentSeniority === "experienced":
          translatedSeniority =
            activeTranslation.twoLetterIsoCode === "de" ? "Berufserfahren" : "Experienced";
          break;

        default:
          translatedSeniority =
            activeTranslation.twoLetterIsoCode === "de" ? "Alle anzeigen" : "Show all";
      }

      return translatedSeniority;
    },
    [activeTranslation],
  );

  const groupJobs = useCallback(
    (company, currentSeniority) => {
      const groupedJobs = {};

      // in case of a selected company and/or company - group jobs by filtered categories and filter by company and/or seniority
      switch (true) {
        case company &&
          company !== "Alle anzeigen" &&
          company !== "Show all" &&
          (!currentSeniority ||
            currentSeniority === "Alle anzeigen" ||
            currentSeniority === "Show all"):
          // const newCategories = new Set(
          //   jobs.filter((job) => job.subcompany === company).map((job) => job.recruitingCategory),
          // );

          categories.forEach((category) => {
            groupedJobs[category] = jobs
              .filter((job) => job.recruitingCategory === category)
              .filter((job) => job.subcompany === company);
          });
          break;

        case (!company || company === "Alle anzeigen" || company === "Show all") &&
          currentSeniority &&
          currentSeniority !== "Alle anzeigen" &&
          currentSeniority !== "Show all":
          // const newCategories = new Set(
          //   jobs.filter((job) => job.subcompany === company).map((job) => job.recruitingCategory),
          // );

          categories.forEach((category) => {
            groupedJobs[category] = jobs
              .filter((job) => job.recruitingCategory === category)
              .filter((job) => job.seniority === currentSeniority);
          });
          break;

        case company &&
          company !== "Alle anzeigen" &&
          company !== "Show all" &&
          currentSeniority &&
          currentSeniority !== "Alle anzeigen" &&
          currentSeniority !== "Show all":
          // const newCategories = new Set(
          //   jobs.filter((job) => job.subcompany === company).map((job) => job.recruitingCategory),
          // );

          categories.forEach((category) => {
            groupedJobs[category] = jobs
              .filter((job) => job.recruitingCategory === category)
              .filter((job) => job.seniority === currentSeniority)
              .filter((job) => job.subcompany === company);
          });
          break;

        default:
          // initially group jobs by all existing categories without filtering them by company
          categories.forEach((category) => {
            groupedJobs[category] = jobs.filter((job) => job.recruitingCategory === category);
          });
      }

      return groupedJobs;
    },
    [jobs, categories],
  );

  useEffect(() => {
    // initially selectedCompany will be null, so jobs will be grouped by all existing categories
    const newFilteredJobs = groupJobs(selectedCompany, selectedSeniority);

    setFilteredJobs(newFilteredJobs);
  }, [groupJobs, selectedCompany, selectedSeniority]);

  useEffect(() => {
    const prefilterCompany = query.get("company");
    const possibleCompanies = ["iNOEX", "iBA", "CiTEX", "iDOO GmbH"];

    if (prefilterCompany && possibleCompanies.includes(prefilterCompany)) {
      setSelectedCompany(prefilterCompany);

      setTimeout(() => {
        scrollToPersonio();
      }, 750);
    }
  }, [query, setSelectedCompany]);

  const renderListView = useCallback(() => {
    return (
      <>
        <div className="personio-select-wrapper">
          <select
            className="form-select personio-select"
            value={selectedCompany}
            onChange={(e) => {
              e.preventDefault();

              setSelectedCompany(e.target.value);
            }}
          >
            {[null, ...companies].map((company, index) => {
              return (
                <option
                  key={index}
                  value={
                    company ||
                    (activeTranslation.twoLetterIsoCode === "de" ? "Alle anzeigen" : "Show all")
                  }
                >
                  {company ||
                    (activeTranslation.twoLetterIsoCode === "de" ? "Alle anzeigen" : "Show all")}
                </option>
              );
            })}
          </select>
          <select
            className="form-select personio-select"
            value={selectedSeniority}
            onChange={(e) => {
              e.preventDefault();

              setSelectedSeniority(e.target.value);
            }}
          >
            {[null, ...seniority].map((currentSeniority, index) => {
              return (
                <option
                  key={index}
                  value={
                    currentSeniority ||
                    (activeTranslation.twoLetterIsoCode === "de" ? "Alle anzeigen" : "Show all")
                  }
                >
                  {translateSeniority(currentSeniority)}
                </option>
              );
            })}
          </select>
        </div>
        {/* <ul className="nav nav-pills personio-list-companies d-none">
          {[null, ...companies].map((company) => {
            return (
              <li key={`key_${company || Math.random()}`} className="nav-item">
                <a
                  href="#"
                  className={`nav-link ${company === selectedCompany ? "active" : ""}`.trim()}
                  onClick={(e) => {
                    e.preventDefault();

                    setSelectedCompany(company);
                  }}
                >
                  {company ||
                    (activeTranslation.twoLetterIsoCode === "de" ? "Alle anzeigen" : "Show all")}
                </a>
              </li>
            );
          })}
        </ul> */}

        <div className="mt-small">
          <StandardPersonioList
            filteredJobs={filteredJobs}
            translations={{
              jobDetails:
                activeTranslation.twoLetterIsoCode === "de"
                  ? "Jobprofil ansehen"
                  : "View job details",
            }}
          />
        </div>
      </>
    );
  }, [
    filteredJobs,
    companies,
    selectedCompany,
    activeTranslation,
    translateSeniority,
    seniority,
    selectedSeniority,
  ]);

  return (
    <div className="container personio standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {Object.keys(filteredJobs).length > 0 && <div className="mt-small">{renderListView()}</div>}
    </div>
  );
};

StandardPersonio.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardPersonio;
